import React, { useState, useContext } from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import "@styles/Faqs.css";
import { IpContext } from "@components/contexts/IpContext";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import SmallBanner from "@components/common/SmallBanner";
import { Link } from "gatsby";

export default function PreguntasFrecuentes(props) {
    return (
        <>
            <Layout>
                <Seo
                    title="Preguntas frecuentes"
                    description="Todas tus dudas acerca de nuestros productos"
                    tags="ayuda,faq,faqs,preguntas,frecuentes"
                />
                <SmallBanner
                    title="Preguntas frecuentes"
                    subtitle="Todas tus dudas en un único lugar."
                    filename="bgs/bgFaq"
                />

                <div className={`faqs ${props.className}`}>
                    <div className="container lg:px-0 py-20">
                        <Title>Aplicaciones</Title>
                        <Text className="text-justify">
                            ¿Tienes alguna duda acerca de nuestra aplicaciones? A continuación te
                            mostramos las preguntas más recurrentes solicitadas por nuestros
                            usuarios. En caso que no encuentres la tuya puedes contactarnos a través
                            de nuestra sección de{" "}
                            <Link to="/contacto#form" className="default-link">
                                Contacto
                            </Link>{" "}
                            y seleccionar como asunto el nombre de la app sobre la que quieres
                            preguntar.
                        </Text>

                        <div className="grid grid-cols-1 gap-5 mt-10 mb-10">
                            <Faq title="¿Necesito estar registrado para acceder a la aplicación?">
                                Sí. Para poder acceder a la aplicación y que podamos guardar tu
                                información de forma correcta tienes que autentificarte en la
                                aplicación mediante alguna cuenta de correo (de momento sólo
                                disponible Gmail). En breve ofreceremos otros métodos alternativos
                                de autenticación.
                            </Faq>

                            <Faq title="¿Puedo utilizar la aplicación en más de un dispositivo?">
                                Sí. Puedes hacerlo sin problemas. Sólo tendrás de identificarte con
                                tu cuenta de Gmail y tendrás todos tus datos en el otro terminal.
                                <br />
                                <br />
                                <strong>Aviso:</strong> no es posible compartir cuentas entre
                                usuarios. El uso indebido de su cuenta de correo para acceder a la
                                aplicación puede ocasionar el bloqueo de ésta por tiempo indefinido.
                            </Faq>

                            <Faq title="¿Cómo puedo conseguir los módulos?" hidden={true}>
                                Los módulos deben adquirirse a través de la aplicación y el pago se
                                debe realizar mediante Paypal. Aviso: la duración de la suscripción
                                de cada módulo es de 1 año a partir de la fecha de activación.
                            </Faq>

                            <Faq title="¿Expiran las suscripciones a los módulos?" hidden={true}>
                                Sí. La suscripción de los módulos caduca al año de ser contratados,
                                a no ser que la suscripción sea por otro intérvalo de tiempo.
                            </Faq>

                            <Faq
                                title="¿Puedo renovar un módulo antes de que caduque?"
                                hidden={true}
                            >
                                Sí. En el menú lateral Tienda (de la aplicación) podrás renovar
                                cualquier suscripción a un módulo por un año adicional tantas veces
                                como desee.
                            </Faq>

                            <Faq
                                title="¿Perderé días de suscripción si renuevo un módulo antes de que haya expirado o caducado?"
                                hidden={true}
                            >
                                No. Cuando renuevas un módulo se añaden 365 días más a los que te
                                quedaban por finalizar la suscripción.
                            </Faq>

                            <Faq title="¿Cómo puedo impugnar una pregunta de la aplicación que pienso que tiene un error?">
                                Hemos añadido una opción para impugnar preguntas. Para impugnar una
                                pregunta solo has de ir al menú Histórico, marcar un exámen y hacer
                                clic encima de una pregunta y se automatizará el proceso haciéndonos
                                llegar un correo con la pregunta incorrecta. Si lo prefieres puedes
                                mandarnos una captura de pantalla con las preguntas a la siguiente
                                dirección de correo{" "}
                                <a href="mailto:info@iopos.academy" className="link">
                                    info@iopos.academy
                                </a>{" "}
                                indicándonos el error.
                            </Faq>

                            <Faq
                                title="Puedo pagar una suscripción de otro modo que no sea Paypal"
                                hidden={true}
                            >
                                No. Actualmente la aplicación sólo soporta este método de pago. Para
                                más información puedes mandarnos un correo a{" "}
                                <a href="mailto:info@iopos.academy" className="link">
                                    info@iopos.academy
                                </a>
                                .
                            </Faq>

                            <Faq title="¡No me deja acceder a la aplicación!">
                                ¡Cálmate y no te preocupes! Cualquier problema que tengas con la
                                aplicación no dudes en contárnoslo a través del correo{" "}
                                <a href="mailto:info@iopos.academy" className="link">
                                    info@iopos.academy
                                </a>
                                . ¡Estaremos encantados de ayudarte!
                            </Faq>

                            <Faq title="¿Puedo devolver un módulo adquirido?" hidden={true}>
                                <div>¡Sí!</div>

                                <div className="mt-2">
                                    <h1 className="font-semibold">Módulos de Test:</h1>
                                    <div>
                                        Una vez adquirido el módulo dispones de 14 días para
                                        realizar un cambio o para su devolución y posterior
                                        reembolso, siempre y cuando no se hayan realizado más de 15
                                        test en dicho módulo.
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <h1 className="font-semibold">Módulos de Podcast:</h1>
                                    <div>
                                        Una vez adquirido el módulo dispones de 5 días para realizar
                                        un cambio o para su devolución y posterior reembolso.
                                    </div>
                                </div>

                                <div className="mt-5">
                                    ¡Atención! en ningún caso se aceptará la compra de un módulo con
                                    oferta tras una devolucion del mismo. Para más información
                                    contacta con nosotros a través del correo{" "}
                                    <a href="mailto:info@iopos.academy" className="link">
                                        info@iopos.academy
                                    </a>
                                </div>
                            </Faq>

                            <Faq
                                title="¿Cuánto tardan en hacerse efectivas las devoluciones?"
                                hidden={true}
                            >
                                Las devoluciones se harán mediante el mismo método de pago, por lo
                                qué será Paypal el que abone el reembolso en su cuenta (entre 6 y 10
                                días laborables). Para más información consulta la página de ayuda
                                de Paypal.
                            </Faq>

                            <Faq title="¿Puedo ejecutar la aplicación en un PC?">
                                No, la aplicación únicamente está disponible para sistemas Android e
                                iOS. De todos modos, puedes instalar un emulador de Android y así
                                poder ejecutar la versión para Android. Hay varios emuladores
                                disponibles en el mercado, y algunos de ellos funcionan muy bien con
                                nuestra aplicación. Te recomendamos{" "}
                                <a
                                    href="https://www.bluestacks.com/es/index.html"
                                    className="link"
                                    rel="noopener noreferrer"
                                >
                                    BlueStacks
                                </a>{" "}
                                y
                                <a
                                    href="http://www.memuplay.com/"
                                    rel="noopener noreferrer"
                                    className="link"
                                >
                                    MemuPlay
                                </a>
                                .
                            </Faq>

                            <Faq title="¿Cómo se calcula la puntuación del ranking?">
                                La puntuación del ranking se basa en varios factores que evalúan tu
                                desempeño en el test. Cada respuesta correcta contribuye
                                positivamente a tu puntuación, mientras que las respuestas
                                incorrectas tienen un impacto negativo. No se penalizan las
                                respuestas en blanco. Además, se tiene en cuenta el tiempo empleado
                                en completar el test, donde se aplica una pequeña penalización por
                                tiempo extra. También consideramos el número de preguntas del test,
                                valorando positivamente la realización de tests con un mayor número
                                de preguntas. En resumen, tu puntuación final refleja tanto la
                                precisión en tus respuestas como tu eficiencia en la gestión del
                                tiempo durante el test.
                            </Faq>
                        </div>

                        <Title>Libros</Title>
                        <Text className="text-justify">
                            En esta sección intentaremos resolver las dudas más recurrentes
                            solicitadas por nuestros usuarios. En caso que no encuentres la tuya
                            puedes contactarnos a través de nuestra sección de{" "}
                            <Link to="/contacto#form" className="default-link">
                                Contacto
                            </Link>{" "}
                            y seleccionar como asunto <i>Libros</i>.
                        </Text>

                        <div className="grid grid-cols-1 gap-5 mt-10 mb-10">
                            <Faq title="¿Cuánto tardan en enviarse los libros?">
                                Los libros se envían en el día siguiente a la compra a través del
                                servicio de envíos de Correos. Puede tardar de 4 a 7 días, en
                                condiciones normales. El envío no es certificado, es un envío
                                ordinario.
                            </Faq>
                        </div>

                        <Title>Cursos</Title>
                        <Text className="text-justify">
                            En esta sección intentaremos resolver las dudas más recurrentes
                            solicitadas por nuestros usuarios. En caso que no encuentres la tuya
                            puedes contactarnos a través de nuestra sección de{" "}
                            <Link to="/contacto#form" className="default-link">
                                Contacto
                            </Link>{" "}
                            y seleccionar como asunto <i>Cursos</i>.
                        </Text>

                        <div className="grid grid-cols-1 gap-5 mt-10 mb-10">
                            <Faq title="¿Los cursos son presenciales o online?">
                                Hay de los dos y se indicará en cada anuncio del curso. Los que sean
                                remotos o online se realizarán en nuestra{" "}
                                <a
                                    href="https://cursos.iopos.es"
                                    rel="nooponer noreferrer"
                                    target="_blank"
                                    className="default-link"
                                >
                                    Plataforma de Cursos
                                </a>
                                , y los presenciales se realizarán dependiendo del número de
                                inscritos un un sitio u en otro.
                            </Faq>

                            <Faq title="¿Puedo solicitar la devolución del importe de un curso presencial?">
                                Sí, y solo si lo pides 5 días antes del inicio del curso. En caso
                                contrario no será posible realizar el reembolso.
                                <br />
                                <br />
                                Para ello puedes ir a la sección de{" "}
                                <Link to="/contacto/" className="default-link">
                                    Contacto
                                </Link>{" "}
                                y enviarnos un mensaje para que procedamos a realizar la devolución.
                            </Faq>

                            <Faq title="¿Puedo solicitar la devolución del importe de un curso online o remoto?">
                                Sí, y solo si lo pides 5 días después de la compra del curso. En
                                caso contrario no será posible realizar el reembolso.
                                <br />
                                <br />
                                Para ello puedes ir a la sección de{" "}
                                <Link to="/contacto/" className="default-link">
                                    Contacto
                                </Link>{" "}
                                y enviarnos un mensaje para que procedamos a realizar la devolución.
                            </Faq>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}

const Faq = ({ title, children, startOpened, hidden }) => {
    const { warningIp } = useContext(IpContext);
    const [expanded, setExpanded] = useState(startOpened);

    return (
        <>
            {!warningIp || !hidden ? (
                <div className="rounded overflow-hidden ">
                    <div className="section-title">
                        <button
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                            className="w-full flex justify-between items-center"
                        >
                            <h4 className="font-bold text-left text-sm font-montserrat">{title}</h4>

                            <div className="icon">
                                {!expanded ? (
                                    <AiOutlineArrowDown className="w-5" />
                                ) : (
                                    <AiOutlineArrowUp className="w-5" />
                                )}
                            </div>
                        </button>
                    </div>
                    <div
                        className={`overflow-hidden duration-300 ${
                            expanded ? "h-auto opacity-100" : "h-0 opacity-0"
                        }`}
                    >
                        <div className="faq-text">{children}</div>
                    </div>
                </div>
            ) : null}
        </>
    );
};
